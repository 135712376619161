<template>
  <b-button-group
    :vertical="type === 'mobile'"
  >
    <pressure-button
      v-for="option in options"
      :key="option.value"
      :pressed="value === option.value"
      :type="type"
      :class="{'grouped-pressure-button-desktop': type === 'desktop'}"
      @click="onClick(option.value)"
    >
      {{ option.label }}
    </pressure-button>
  </b-button-group>
</template>

<script>
import PressureButton from './PressureButton'

export const TYPES = ['desktop', 'mobile']

/**
 * Group of pressure buttons of which only 1 can be selected at a time.
 *
 * Should be used when the user is given a choice between a small number of
 * options, and choosing one triggers an some kind of event.
 *
 * For a large number of choices, use a dropdown, a combobox, or something
 * similar.
 *
 * For choices that don't trigger a loading event, a radio group is usually
 * more appropriate.
 *
 * (*Never* use radio groups that can trigger loading events, because they are
 * horrible for keyboard usability!)
 *
 * For choices that have multiple selectable values, use checkboxes, or just a
 * basic `<b-button-group>` with pressure buttons.
 *
 * Just like `PressureButton`, has 2 types: 'desktop' and 'mobile'. Rename them
 * or add new ones whenever necessary.
 *
 * @see <https://verkkoaps.atlassian.net/wiki/spaces/CPLTD/pages/161284097/UI+UX+Guidelines>
 */
export default {
  name: 'PressureButtonSelection',
  components: { PressureButton },
  props: {
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => TYPES.includes(value)
    },
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    onClick (newValue) {
      // Prevent re-click of selected button from sending event:
      if (newValue !== this.value) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* Add a separator between the buttons: */
.grouped-pressure-button-desktop:not(:first-child) {
  border-left: solid 2px $gray-500;
}
</style>
