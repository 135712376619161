<template>
  <div
    class="w-100"
  >
    <search-indicator
      v-show="searchText"
      @reset="resetSearch"
    >
      {{ searchText }}
    </search-indicator>
    <order-list-mobile
      class="d-lg-none h-100"
    />
    <!-- loading is temporarily bound to ongoingQuery until the desktop view ha been refactored to use the Vuex actions -->
    <order-table
      class="d-none d-lg-table"
    />
  </div>
</template>

<script>
/**
 * Wrapper to desktop and mobile view components containing all single-row and expandable Tracking Statuses
 */
import SearchIndicator from '@/components/Search/SearchIndicator'
import OrderListMobile from './Mobile/OrderListMobile'
import OrderTable from './Desktop/OrderTable/OrderTable'

export default {
  name: 'OrderList',
  components: { OrderTable, OrderListMobile, SearchIndicator },
  computed: {
    searchQuery () {
      let searchQuery = ''
      for (const searchItem of this.filters.search) {
        if (searchQuery) {
          searchQuery += ' '
        }
        searchQuery += searchItem
      }
      return searchQuery
    },
    searchText () {
      return this.$store.getters['statusMonitor/searchText']
    },
    ongoingQuery () {
      return this.$store.getters['statusMonitor/ongoingQuery']
    }
  },
  methods: {
    resetSearch () {
      const queryParameters = {
        query: '',
        page: 1,
        extraParams: {}
      }
      this.$store.dispatch('statusMonitor/search', queryParameters)
    }
  },
  created () {
    // FIXME: Placeholder for loading mobile (and desktop) initial list
    if (this.$store.getters['statusMonitor/firstFetchDone'] === false) {
      if (this.ongoingQuery) {
        // This happend when the route change comes from eg. search and not a
        // direct page load.
        return
      }
      const queryParameters = {
        // Check if this is "copy-pasted" or bookmarked url.
        query: this.$router.currentRoute.query?.query ?? '',
        page: this.$router.currentRoute.query?.page ?? 1
        // FIXME: Add other parameters.
      }
      this.$store.dispatch('statusMonitor/search', queryParameters)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
