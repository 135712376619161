<template>
  <b-tr
    class="expand-row"
    :data-row-type="item.rowType"
    :data-delivery-status="item.deliveryStatus || 'UnknownDeliveryStatus'"
    :data-stage="item._stage || 'StageMissing'"
    :data-sales-order-doc-number="item.salesOrderDocNumber || '_na_'"
    :data-purchase-order-doc-number="item.purchaseOrderDocNumber || '_na_'"
    :data-reservation-doc-number="item.reservationDocNumber || '_na_'"
    :data-delivery-order-doc-number="item.deliveryOrderDocNumber || '_na_'"
  >
    <b-th
      class="font-weight-normal"
      :class="{ 'text-success': item.deliveryStatus === DELIVERED }"
      data-column-name="Delivery date"
      >
      <div
        v-if="item.rowType === 'materialCodeItem'"
        class="d-flex align-items-center in-one-line"
        :style="{ color: deliveryDateColor }"
        >
        <span v-if="deliveryDateIsEta" data-variable="deliveryDateIsEta">{{ eta | formatResponseDate }} {{ messages.getFieldEta() }}</span>
        <span v-else data-variable="deliveryDate">{{ deliveryDate || eta | formatResponseDate }}</span>
        <i
          v-if="item.deliveryStatus === TO_BE_PICKED_UP"
          class="icon-follow text-warning icon-size ml-2"
        />
      </div>
    </b-th>
    <b-th
      class="font-weight-normal flex-row"
      data-column-name="Service order"
    >
      <div class="icon-container sub-item-pad">
        <div
          v-if="item.rowType === 'materialCodeItem'"
          class="d-flex"
        >
          <notification-icon
            :sms-notifications='smsNotifications'
            v-if="showNotificationIcons"
            class="mr-2"
          />
          <i
            class="icon-equipment-2 icon-size grey-text"
          />
        </div>
      </div>
      <div v-if="item.rowType === 'materialCodeGroup'" class="flex-column pl-3">
        <div data-variable="item.materialCode">
          <span v-if="materialCodeUrl">
            <b-link
              :href="materialCodeUrl"
              target="_blank"
            >
              {{ item.materialCode | formatMissing(messages.getLabelNoMaterialCode()) }}
            </b-link>
          </span>
          <div
            v-else
          >
            {{ item.materialCode | formatMissing(messages.getLabelNoMaterialCode()) }}
          </div>
        </div>
      </div>
      <div v-if="item.rowType === 'reservationRow'" class="flex-column pl-3 gray-text">
        <div>
          <span data-variable="Total quantity reserved or ordered">
            {{ item._salesOrderScheduleLineAndDeliveryQuantitySum || item._purchaseOrderQuantity || item._reservationQuantitySum | formatMissing(messages.getLabelNoUnit()) }}

          </span>
          <span data-variable="item._unit"> {{ item._unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
          <span v-if="item._materialDescription" data-variable="item._materialDescription">
            , {{ item._materialDescription | formatMissing(messages.getLabelNoMaterialDescription()) }}
          </span>
        </div>
      </div>
      <div v-if="item.rowType === 'materialCodeItem'" class="flex-column pl-3 gray-text" :data-stage="item._stage" :data-sap-flow-type="sapFlowType">
        <div v-if="item._stage === trackingStatusStages.DELIVERY_ORDER_ITEM">
          <span data-variables="item.deliveryQuantity item.calculateSalesOrderScheduleLineAndDeliveryQuantitySum">
            {{ item.deliveryQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._salesOrderScheduleLineAndDeliveryQuantitySum | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit())}}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
        <div v-if="item._stage === trackingStatusStages.SALES_ORDER_SCHEDULE_LINE_ITEM">
          <span data-variables="item.salesOrderScheduleLineQuantity item.calculateSalesOrderScheduleLineAndDeliveryQuantitySum">
            {{ item.salesOrderScheduleLineQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._salesOrderScheduleLineAndDeliveryQuantitySum | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
        <div v-else-if="item._stage === trackingStatusStages.SALES_ORDER_ITEM" :data-sales-order-doc-number="item.salesOrderDocNumber">
          <span data-variables="item.salesOrderQuantity item.reservationQuantitySum">
            {{ item.salesOrderQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._reservationQuantitySum | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
         <div v-else-if="item._stage === trackingStatusStages.PURCHASE_ORDER_GOODS_RECEIPT_ITEM">
          <span data-variables="item.purchaseOrderGoodsReceiptQuantity item._purchaseOrderQuantity">
            {{ item.purchaseOrderGoodsReceiptQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._purchaseOrderQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
         <div v-else-if="item._stage === trackingStatusStages.PURCHASE_ORDER_CONFIRMATION_ITEM">
          <span data-variables="item.purchaseOrderConfirmationQuantity item._purchaseOrderQuantity">
            {{ item.purchaseOrderConfirmationQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._purchaseOrderQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
        <div v-else-if="item._stage === trackingStatusStages.PURCHASE_ORDER_ITEM">
          <span data-variables="item.purchaseOrderQuantity item.reservationQuantitySum">
            {{ item.purchaseOrderQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._reservationQuantitySum || item.purchaseOrderQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
        <div v-else-if="item._stage === trackingStatusStages.RESERVATION_ITEM">
          <span data-variables="item.reservationQuantity item.reservationQuantitySum">
            {{ item.reservationQuantity | formatMissing(messages.getLabelUnknownValueMarker()) }}{{ messages.getLabelRelatedValuesSeparator() }}{{ item._reservationQuantitySum | formatMissing(messages.getLabelUnknownValueMarker()) }}
          </span>
          <span data-variable="item.unit"> {{ item.unit | formatMissing(messages.getLabelNoUnit()) }}</span>
          <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
        </div>
        <div v-else-if="item._stage === trackingStatusStages.SERVICE_ORDER">
          <span>({{ messages.getLabelServiceOrderStage() }})</span>
        </div>
        <div v-else>
          <!--
          <span data-variables="item.TODO_quantity item.TODO_sum">X/Y</span>
          <span data-variable="item.TODO_unit">Z</span>
          <span data-variable="item._materialDescription">, {{ item._materialDescription }}</span>
          -->
        </div>
      </div>
    </b-th>
    <b-th
      class="font-weight-normal"
      data-column-name="Purchase order"
    >
      <div
        v-if="item.rowType === 'materialCodeItem'"
      >
        <span
          data-variable="purchaseOrderFixme"
          v-if="!toBePurchased"
        >
          <span>
            {{ purchaseOrderFixme }}
          </span>
          <tag-badge :salesOrderDocFlow="salesOrderDocFlow" />
        </span>
        <div
          v-else
          class="d-flex align-items-center"
        >
          <span>
            {{ actionMap.TO_BE_PURCHASED }}
          </span>
          <i
            class="icon-follow icon-size text-warning ml-2"
          />
        </div>
      </div>
    </b-th>
    <b-th
      class="text-center"
      data-column-name="Confirmation"
      :data-confirmation-color="confirmationColor"
    >
      <i
        v-if="confirmationColor"
        class="icon-assignment icon-size"
        :style="{ color: confirmationColor }"
        v-b-tooltip.hover
        :title="salesOrderDocNumber"
      />
    </b-th>
    <b-th
      class="text-center"
      data-column-name="Delivery"
      :data-delivery-color="deliveryColor"
    >
      <div
        v-if="deliveryColor"
      >
        <i
          class="icon-truck-1 icon-size"
          :style="{ color: deliveryColor }"
          v-b-tooltip.hover
          :title="item.deliveryOrderDocNumber"
          :id="componentId"
        />
        <transport-details-tooltip
          :delivery-order-doc-number="deliveryOrderDocNumber"
          :transport-details="item.transportDetails"
          :target="componentId"
        />
      </div>
    </b-th>
    <b-th data-column-name="Destination">
      <div
        v-if="item.rowType === 'materialCodeItem'"
        class="d-flex font-weight-normal"
      >
        <div
          class="icon-container"
        >
          <i
            class="icon-size"
            :class="destinationIcon"
          />
        </div>
        <div
          class="pl-2 flex-column column-dark-text"
          :class="{ 'text-body': item.deliveryStatus === DELIVERED || item._stage === trackingStatusStages.PURCHASE_ORDER_GOODS_RECEIPT_ITEM}"
        >
          <span data-variable="destination.deliveryAddress">{{ destinationAddressFixme }}</span>
        </div>
      </div>
    </b-th>
    <b-th data-column-name="(Expand icon)"></b-th>
  </b-tr>
</template>

<script>
// TODO: using v-if-else to determine which data we should display is pretty dumb. Create separate
// templates for all the row types (materialCodeGroup, reservationRow and materialCodeItem). Slots
// might be useful here, for example slot for each cell (serice order slot, purchase order slot etc.)
import { DELIVERED, TO_BE_PICKED_UP } from '@/constants/shared-constants'
import actionMap from '@/constants/action-map'
import { messages } from '@/utils/strings'
import { trackingStatusStages } from '@/components/StatusMonitor/tracking-status'
import { formatMissing, formatResponseDate, formatPurchaseOrderInfo } from '@/components/StatusMonitor/formatters'
import { getConfirmationColor, getDeliveryColor, getDestinationIcon, getDeliveryDateColor } from '@/components/StatusMonitor/colors'
import NotificationIcon from '@/components/StatusMonitor/Desktop/OrderTable/NotificationIcon'
import TransportDetailsTooltip from '@/components/StatusMonitor/Desktop/OrderTable/TransportDetailsTooltip'
import TagBadge from '@/components/OrderDetails/TagBadge'
import { TAGS } from '@/constants/tags'

export default {
  name: 'ExpandRow',
  components: { TransportDetailsTooltip, NotificationIcon, TagBadge },
  props: {
    item: { // TrackingStatusItem-like object.
      required: true,
      type: Object
    }
  },
  filters: {
    formatMissing,
    formatResponseDate
  },
  created () {
    this.GSS = TAGS.GSS
    this.LOCAL = TAGS.LOCAL
    this.DELIVERED = DELIVERED
    this.TO_BE_PICKED_UP = TO_BE_PICKED_UP
    this.messages = messages
    this.actionMap = actionMap
  },
  computed: {
    deliveryDateColor () {
      // console.log(this.item)
      return getDeliveryDateColor(this.item)
    },
    sapFlowType () {
      // sapFlowType is Inbound, Outbound or NA if not set
      if (this.item?.sapFlowType) {
        return this.item.sapFlowType
      }
      return messages.getLabelNa()
    },
    salesOrderDocFlow () {
      if (this.item.salesOrderDocNumber) {
        return this.GSS
      }
      return this.LOCAL
    },
    componentId () {
      // unique vue component id
      return this._uid.toString()
    },
    materialCodeUrl () {
      return this.$store.getters.createMaterialCodeUrl(this.item.materialCode)
    },
    purchaseOrderLink () {
      if (this.item.purchaseOrderDocNumber) {
        return `https://parts.kone.com/Tracking/OrderTrackingSearchResult?searchTerms=${this.item.purchaseOrderDocNumber}`
      } else {
        return null
      }
    },
    trackingStatusStages () {
      return trackingStatusStages
    },
    smsNotifications () {
      let itemDetails = []
      if (this.item.accessPointPhoneNumber) {
        const accessPointPhoneNumberLine = { accessPhoneNumber: this.item.accessPointPhoneNumber }
        const smsTimestamps = this.item.smsTimestamps ?? []
        const smsDates = smsTimestamps.map(timestamp => ({ timestamp }))
        itemDetails = [accessPointPhoneNumberLine, ...smsDates]
      }
      return itemDetails
    },
    showNotificationIcons () {
      // Show SMS notification icons when there are SMS notifications
      return this.smsNotifications.length > 1 // > 1 means it has at least accessPointPhoneNumber and 1 timestamp
    },
    toBePurchased () {
      return !this.item.purchaseOrderDocNumber && !this.item.salesOrderDocNumber &&
        !this.item.deliveryOrderDocNumber && this.item.reservationDocNumber
    },
    eta: function () {
      return this.item.eta
    },
    confirmationColor: function () {
      return getConfirmationColor({
        stage: this.item._stage,
        deliveryStatus: this.item.deliveryStatus,
        deliveryType: this.item.deliveryType,
        salesOrderDocNumber: this.item.salesOrderDocNumber,
        purchaseOrderConfirmationCategory: this.item.purchaseOrderConfirmationCategory
      })
    },
    deliveryColor: function () {
      return getDeliveryColor(this.item)
    },
    destinationAddressFixme: function () {
      // FIXME: Remove this once deliveryAddress is fixed in backend
      // console.log({ item: this })
      return this.item.deliveryAddress || this.item.addressCode + ' ' + messages.getLabelRelatedValuesSeparator() + ' ' + this.item.storageLocation || `(${messages.getLabelNoAddressCode()})`
    },
    purchaseOrderFixme: function () {
      return formatPurchaseOrderInfo(this.item)
    },
    destinationIcon: function () {
      return getDestinationIcon(this.item)
    },
    deliveryOrderDocNumber: function () {
      return this.item.deliveryOrderDocNumber
    },
    deliveryDateIsEta: function () {
      return this.item?.deliveryDateIsEta
    },
    deliveryDate: function () {
      return this.item?.deliveryDate
    },
    trackingNumber: function () {
      return this.item?.trackingNumber
    }
  },
  methods: {
    salesOrderDocNumber: function () {
      // This needs to be returned as methods otherwise information from wrong items can be shown when
      // the item doesn't have salesOrderDocNumber. See the Jira bug CPL-583 for more information.
      return this.item?.salesOrderDocNumber
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/custom.scss';

$icon-font-size: 26px;
.icon-size {
  font-size: $icon-font-size;
}
.expand-row {
  background-color: $secondary!important;
}
.gray-text {
  color: $gray-600;
}
.icon-equipment-2 {
  color: $gray-400;
}
.icon-container {
  min-width: $icon-font-size;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.cell-icon-container {
  height: 26px;
  width: 26px;
}
.sub-item-pad {
  padding-left: 48px;
}
.green {
  color: $green;
}
.gray {
  color: $gray-400;
}
.column-dark-text {
  color: $gray-900;
}
.tracking-number {
  color: $gray-900;
  margin-left: 30px;
}
.in-one-line {
  white-space: nowrap;
}
</style>
