<template>
  <page-layout>
    <template v-slot:topBar>
      <status-monitor-top-panel class="d-block d-lg-none"/>
    </template>
    <filter-panel class="d-none d-lg-flex"/>
    <order-list/>
  </page-layout>
</template>

<script>
import StatusMonitorTopPanel from '../components/StatusMonitor/Mobile/StatusMonitorTopPanel'
import OrderList from '../components/StatusMonitor/OrderList'
import PageLayout from '../components/PageLayout'
import FilterPanel from '../components/StatusMonitor/Desktop/FilterPanel'

export default {
  name: 'StatusMonitor',
  components: { FilterPanel, PageLayout, StatusMonitorTopPanel, OrderList }
}
</script>

<style scoped>
</style>
