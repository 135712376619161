<template>
  <b-button
    @click="onClick"
    block
    squared
    class="top-panel-button"
  >
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'TopPanelButton',
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
/*
 * This style requires a double class now (but not before), because for some
 * reason, the `b-btn` styles come after it in the `<style>` element order.
 *
 * FIXME: @Henrik: Figure out how to have only one class here.
 */
.top-panel-button.top-panel-button {
  background-color: $secondary;
  font-size: 18px;
  height: 54px;
  border: none;
  text-align: left;
  color: unset;
  padding-left: 32px;

  &:focus {
    box-shadow: none;
  }
}
</style>
