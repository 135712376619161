<template>
  <div>
    <i
      class="icon-size icon-bubble text-primary"
      :id="componentID"
    />
    <b-tooltip
      :target="componentID"
      triggers="hover"
      custom-class="tooltip-width"
    >
      <ul
      v-if="tooltips"
      class="pl-3 text-left mb-0 pr-3"
    >
      <li
        v-for="(detail, key) in tooltips"
        :key="key"
        class="mb-1"
      >
        <div>{{ detail }}</div>
      </li>
    </ul>
    </b-tooltip>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'

export default {
  name: 'NotificationIcon',
  props: {
    smsNotifications: {
      type: Array,
      required: true
    }
  },
  computed: {
    componentID () {
      // unique vue component id
      return this._uid.toString()
    },
    tooltips () {
      const tooltips = []
      const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
      }
      for (const item of this.smsNotifications) {
        if (item.accessPhoneNumber) {
          tooltips.push(messages.getTooltipSmsSentTo({ phoneNumber: item.accessPhoneNumber }))
        } else {
          tooltips.push(new Intl.DateTimeFormat('default', dateOptions).format(item.timestamp))
        }
      }
      return tooltips
    }
  }
}
</script>

<style scoped>
.icon-size {
  font-size: 26px;
}
ul {
  list-style-type: none;
  padding-left: 2px;
  width: 100% !important;
}
li {
  white-space: nowrap;
}
.tooltip-width >>> .tooltip-inner {
  max-width: 100% !important;
}
</style>
