<template>
  <b-table-simple
    borderless
  >
    <b-thead>
      <b-tr>
        <b-th
          v-for="(field, key) in fields"
          :key="key"
          class="font-weight-normal"
        >
          {{ field }}
        </b-th>
      </b-tr>
    </b-thead>
    <!-- Disabled as the LoadingSpinnerCover handles this for now.
    <b-tbody
      v-show="ongoingQuery"
    >
      <loading-row/>
    </b-tbody>
    -->
    <b-tbody
      v-show="!ongoingQuery"
    >
      <b-tr
        v-show="trackingStatuses.length === 0"
        class="bg-light"
      >
        <b-td
          colspan="7"
          class="text-center"
        >
          {{ messages.getLabelNoDataFound() }}
        </b-td>
      </b-tr>
      <template
        v-for="(trackingStatus, summaryRowIndex) in trackingStatuses"
      >
        <order-row
          :key="summaryRowIndex"
          @click="toggleShowExpandRow(summaryRowIndex, trackingStatus)"
          :item="trackingStatus"
          :class="{ 'bg-light': isEven(summaryRowIndex) }"
        />
        <expand-row
          v-for="(row, detailRowIndex) in trackingStatus.expandRows"
          :key="summaryRowIndex.toString() + '-' + detailRowIndex.toString()"
          v-show="showExpandRow(summaryRowIndex)"
          :item="row"
        />
      </template>
    </b-tbody>
    <b-tfoot>
      <b-tr>
        <b-td
          colspan="4"
          class="px-0"
        >
          <b-button
            v-show="selectedPage > 0"
            :disabled="ongoingQuery"
            squared
            class="text-uppercase text-primary"
            @click="previousPage"
          >
            {{ messages.getActionPreviousPage() }}
          </b-button>
        </b-td>
        <b-td
          colspan="3"
          class="px-0 text-right"
        >
          <b-button
            v-show="showNextPageButton"
            :disabled="ongoingQuery"
            squared
            class="text-uppercase text-primary"
            @click="nextPage"
          >
            {{ messages.getActionNextPage() }}
          </b-button>
        </b-td>
      </b-tr>
    </b-tfoot>
  </b-table-simple>
</template>

<script>
import { messages } from '@/utils/strings'
import ExpandRow from './ExpandRow'
import OrderRow from './OrderRow'

export default {
  name: 'OrderTable',
  components: { ExpandRow, OrderRow },
  data () {
    return {
      fixMeTrue: true,
      showExpandRowTable: {},
      messages
    }
  },
  computed: {
    fields () {
      const fields = [
        messages.getFieldDeliveryDate(),
        messages.getFieldServiceOrder(),
        messages.getFieldPurchaseOrder(),
        messages.getFieldConfirmation(),
        messages.getFieldDelivery(),
        messages.getFieldDestination(),
        ''
      ]
      return fields
    },
    selectedPage: {
      get () {
        return this.$store.getters['statusMonitor/selectedPage']
      },
      set (page) {
        // console.log(`Set selected page to ${page}`)
        this.$store.commit('statusMonitor/setSelectedPage', page)
      }
    },
    lastPage () {
      return this.$store.getters['statusMonitor/lastPage']
    },
    hasNextPage () {
      return this.$store.getters['statusMonitor/hasNextPage']
    },
    trackingStatuses () {
      if (this.lastPage) {
        // If there is a last page, there must be at least one page.
        return this.$store.getters['statusMonitor/trackingStatusPage'](this.selectedPage)
      }
      return []
    },
    ongoingQuery () {
      return this.$store.getters['statusMonitor/ongoingQuery']
    },
    showNextPageButton () {
      if (this.selectedPage < this.lastPage?.index) {
        // There is already a pages fetched and we are not in the last page.
        return true
      }
      if (this.hasNextPage) {
        // We are in the last page, but we can fetch more.
        return true
      }
      // We are in the last page and there are no more pages to fetch.
      return false
    }
  },
  watch: {
    trackingStatuses (newTrackingStatuses, oldTrackingStatuses) {
      // Triggered when searching, filtering, or changing pages.
      this.resetExpandRowTable()
    }
  },
  methods: {
    isEven (value) {
      return value % 2 === 0
    },
    toggleShowExpandRow (summaryRowIndex, trackingStatus) {
      // console.log({ _: 'toggleShowExpandRow', summaryRowIndex, showExpandRowTable: this.showExpandRowTable })
      // Undefined counts as "false" and after that toggle between true/false.
      // Using Vue.set is needed as we are adding new properties to the object and
      // dynamically created properties are not reactive using just assignment.
      if (!trackingStatus.trackingStatusItem.serviceOrder) {
        // This check used to be in tracking-status-rows.js but it was causing issues with mobile UI
        // where all rows should show details.
        return false
      }
      this.$set(this.showExpandRowTable, summaryRowIndex, !this.showExpandRowTable[summaryRowIndex])
    },
    showExpandRow (summaryRowIndex) {
      // This is a stupid workaround for the limitations of the order-row expand-row
      // implementation.
      // Note: summaryRowIndex is a Number/integer, but showExpandRowTable is AN OBJECT!
      return this.showExpandRowTable[summaryRowIndex] ?? false
    },
    resetExpandRowTable () {
      this.showExpandRowTable = {}
    },
    ccc (ts, event) {
      console.log({ ts, event })
    },
    nextPage () {
      if (this.selectedPage === this.lastPage.index) {
        if (!this.hasNextPage) {
          // Button should be disabled.
          throw Error('No pages to fetch.')
        }
        // Make a request for next page
        this.$store.dispatch('statusMonitor/fetchNextPage')
          .then((response) => {
            // Switch to the fetched page
            this.selectedPage = this.lastPage.index
          })
      } else {
        this.selectedPage = this.selectedPage + 1
      }
    },
    previousPage () {
      if (this.selectedPage === 0) {
        // Button should be disabled.
        throw Error('Pages can\'t be negative')
      }
      this.selectedPage = this.selectedPage - 1
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  color: $primary;
}
</style>
