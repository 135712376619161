<template>
  <div
      class="sticky-top"
    >
      <status-monitor-top-bar/>
      <status-monitor-dashboard/>
      <status-filter-menu/>
    </div>
</template>

<script>
/**
 * Top panel showing only in mobile view
 *
 * This panel contains
 *   1. Topmost row with buttons to user profile and search as well as current date.
 *   2. Dashboad panel with actions points, arrived and incoming deiliveries indicators.
 *   3. Filter menu, where user can select all, arrived, incoming next and "only my orders".
 */
import StatusMonitorDashboard from './StatusMonitorDashboard'
import StatusFilterMenu from './StatusFilterMenu'
import StatusMonitorTopBar from './StatusMonitorTopBar'

export default {
  name: 'StatusMonitorTopPanel',
  components: { StatusMonitorTopBar, StatusFilterMenu, StatusMonitorDashboard }
}
</script>

<style scoped>
.sticky-top {
  z-index: 1050;
}
</style>
