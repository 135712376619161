<template>
  <b-row no-gutters class="w-100 pt-4">
    <b-col class="search-options-container">
      <search-box-multi
        class= "search-box"
        v-model="searchArray"
        :hint-text="messages.getLabelSearchForStatus()"
        :search-suggestion="searchSuggestionsList"
        :error-indexes="errorIndexes"
        @input="onInput"
    />
      <search-options
        @change="change"
      />
    </b-col>
    <b-col class="d-flex justify-content-end">
      <!-- use v-if to hide calendar -->
      <b-calendar v-if="false" hide-header/>
    </b-col>
  </b-row>
</template>

<script>
/**
 * This is desktop view only panel containing:
 *   1. Free-text search bar
 *   2. Selectable filter buttons for all, arrived and incoming trackings.
 *   3. Calendar widget.
 */
import { messages } from '@/utils/strings'
import SearchBoxMulti from '@/components/FilterPanel/SearchBoxMulti'
import SearchOptions from './SearchOptions'
import { searchInput, formatArraySearchString } from '@/utils/format-search-string'

const MONITOR_SEARCH_INPUTS = {
  access_point_phone_number: searchInput(['access point phone number', 'accesspointphonenumber', 'apn']),
  address_code: searchInput(['address code', 'addresscode', 'ac']),
  carrier: searchInput(['carrier', 'car']),
  created: searchInput(['created', 'cr']),
  delivery_address: searchInput(['delivery address', 'deliveryaddress', 'da']),
  delivery_order_doc_number: searchInput(['delivery order', 'deliveryorder', 'do']),
  delivery_status: searchInput(['delivery status', 'deliverystatus', 'ds']),
  equipment_code: searchInput(['equipment code', 'equipmentcode', 'ec']),
  equipment_description: searchInput(['equipment description', 'equipmentdescription', 'eqd']),
  material_code: searchInput(['material code', 'materialcode', 'mc']),
  material_description: searchInput(['material description', 'materialdescription', 'md']),
  planner_group: searchInput(['planner group', 'plannergroup', 'pg']),
  plant: searchInput(['plant', 'pl']),
  purchase_order_doc_number: searchInput(['purchase order', 'purchaseorder', 'po']),
  reservation_doc_number: searchInput(['reservation', 'res']),
  sales_order_doc_number: searchInput(['sales order', 'salesorder', 'so']),
  service_order: searchInput(['service order', 'serviceorder', 'so']),
  ship_to_party: searchInput(['ship to party', 'shiptoparty']),
  storage_location: searchInput(['storage location', 'storagelocation', 'sloc']),
  tracking_number: searchInput(['tracking number', 'trackingnumber', 'tn']),
  transport_details: searchInput(['transport details', 'transportdetails', 'td']),
  work_center: searchInput(['work center', 'workcenter', 'wc'])
}
export default {
  name: 'FilterPanel', // FIXME: See that this is not confused with StatusFilterMenu
  components: { SearchBoxMulti, SearchOptions },
  data () {
    return {
      // We need to use model and store the search text here as the SearchOptions
      // can change independently and the even payload does not contain the
      // free text search.
      // The searchText in the Vuex store is updated by the action "search".
      searchText: null,
      searchDict: {},
      searchSuggestionsList: [
        'Access Point Phone Number',
        'Address Code',
        'Carrier',
        'Created',
        'Delivery Address',
        'Delivery Order',
        'Delivery Status',
        'Equipment Code',
        'Equipment Description',
        'Material Code',
        'Material Description',
        'Planner Group',
        'Plant',
        'Purchase Order',
        'Reservation',
        'Sales Order',
        'Service Order',
        'Ship to Party',
        'Storage Location',
        'Tracking Number',
        'Transport Details',
        'Work Center'
      ],
      searchArray: [],
      errorIndexes: [],
      messages
    }
  },
  methods: {
    /**
     * Handle user search event.
     *
     * @param {Object} event - Event payload from SearchBox or SearchOptions
     * @param {String} event.source - Source where the event was fired.
     * @param {any} event.value - Optional payload.
     */
    change (event) {
      // console.log({ _: 'FilterPanel.change', event, searchText: this.searchText })
      //
      // In practise we ignore the event source and payload as the end result is
      // the same: Make a new search.
      //
      // Because we are only passing the query parameter, the "statusMonitor/search"
      // action is using the values stored in Vuex and updated by the SearchOptions
      // component.
      //
      // The searchText is also stored in Vuex, but the "statusMonitor/search" has
      // query as a required parameter. We could also use a Vuex getter to provide
      // that parameter.
      this.$store.dispatch('statusMonitor/search', { query: this.searchText, extraParams: this.searchDict })
    },
    onInput (value, performSearch) {
      const { searchDict, errorIndexes } = formatArraySearchString(MONITOR_SEARCH_INPUTS, this.searchArray)
      this.errorIndexes = errorIndexes
      // for each value in searchDict remove first char
      Object.keys(searchDict).forEach(key => {
        searchDict[key] = searchDict[key].slice(1)
        if (key === 'search') {
          this.searchText = searchDict[key].replaceAll(',', ' ')
          delete searchDict[key]
        }
      })
      this.searchDict = searchDict
      if (performSearch) {
        this.change()
      }
    }
  }
}
</script>

<style scoped>

</style>
