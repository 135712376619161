<template>
  <b-row
    class="bg-blue w-100 pb-3"
    no-gutters
    cols="2"
  >
    <b-col
      class="text-center h-50"
    >
      <i
        class="icon-maintenance text-white"
      />
    </b-col>
    <dashboard-cell
      :top-text="arrivedCount"
      :bottom-text="messages.getLabelArrived()"
    ></dashboard-cell>
    <dashboard-cell
      :top-text="myActionPoints"
      :bottom-text="messages.getLabelMyActionPoints()"
    ></dashboard-cell>
    <dashboard-cell
      :top-text="incomingCount"
      :bottom-text="messages.getLabelIncomingDeliveries()"
    ></dashboard-cell>
  </b-row>
</template>

<script>
import { messages } from '@/utils/strings'
import DashboardCell from '@/components/TopPanel/DashboardCell'

export default {
  name: 'StatusMonitorDashboard',
  computed: {
    arrivedCount: function () {
      const arrivedCount = this.$store.getters.getArrivedCount
      if (arrivedCount == null) {
        return
      }
      return String(arrivedCount)
    },
    incomingCount: function () {
      const incomingCount = this.$store.getters.getIncomingCount
      if (incomingCount == null) {
        return
      }
      return String(incomingCount)
    },
    myActionPoints: function () {
      const actionPointCount = this.$store.getters.getActionPointCount
      const totalCount = this.$store.getters.getTotalCount
      if (actionPointCount == null || totalCount == null) {
        return
      }
      return [String(actionPointCount), String(totalCount)]
    }
  },
  components: { DashboardCell },
  data () {
    return {
      messages
    }
  }
}
</script>

<style scoped lang="scss">
.bg-blue {
  background-color: $blue-500;
  height: 130px;
}
.icon-maintenance {
  font-size: 42px;
}
</style>
