<template>
  <div
    v-show="animatingDialog || value"
    class="overlay"
    :class="{ 'fade-in': value, 'fade-out': !value }"
    :role="role"
  >
    <button
      title="Close dialog"
      class="close-dialog-button h-100 w-100"
      @click="closeDialog"
    />
    <div class="fixed-bottom">
      <b-form-group
        class="dialog-contents"
        :class="{ 'slide-to-show': value, 'slide-to-hide': !value }"
      >
        <slot />
      </b-form-group>
    </div>
  </div>
</template>

<script>
/**
 * Mobile view only dialog, which shows up at the bottom of the screen and
 * covers the rest of the screen in a dark overlay.
 *
 * Intended to be controlled with a boolean `v-model`, which is true when the
 * dialog is open, and false when the dialog is closed.
 */
export default {
  name: 'BottomDialog',
  props: {
    role: {
      type: String,
      default: 'dialog'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      animatingDialog: false
    }
  },
  methods: {
    closeDialog () {
      this.$emit('input', false)
    }
  },
  watch: {
    value () {
      const animationDuration = 200 // Must match the SCSS variable $dialog-animation-duration
      // Dialog animation triggered.
      this.animatingDialog = true
      setTimeout(() => {
        // After animationDuration dialog is not animating.
        this.animatingDialog = false
      }, animationDuration)
    }
  }
}
</script>

<style scoped lang="scss">
/* Fade in/out the dialog and the modal dark transparent background. */
$dialog-animation-duration: 200ms;
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1040;

  &.fade-in {
    animation-name: fadeIn;
    animation-duration: $dialog-animation-duration;
  }
  &.fade-out {
    animation-name: fadeOut;
    animation-duration: $dialog-animation-duration;
  }
}

.close-dialog-button {
  border-color: Transparent;
  background: Transparent;
}

/* Slide up/down animations for the filter dialog buttons container */
.fixed-bottom {
  .slide-to-show {
    animation-name: slideUp;
    animation-duration: $dialog-animation-duration;
  }
  .slide-to-hide {
    animation-name: slideDown;
    animation-duration: $dialog-animation-duration;
  }
}

.dialog-contents {
  margin-bottom: 0;
  background-color: white;
  /* Rounded top */
  padding-top: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
@keyframes fadeOut {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

</style>
