<template>
  <b-tooltip
    v-if="showTooltip"
    :target="target"
    triggers="hover"
    placement="bottom"
  >
    <ul
      class="pl-3 text-left mb-0 pr-3"
    >
      <li class="delivery-order-doc-number-style">{{deliveryOrderDocNumber}}</li>
      <li
        v-for="(detail, key) in transportDetails"
        :key="key"
        class="mb-1"
      >
        <div>{{ detail.date_time }}</div>
        <div>{{ detail.address }}</div>
        <div>{{ messages.getLabelStatus({ status: detail.status }) }}</div>
      </li>
    </ul>
  </b-tooltip>
</template>

<script>
import { messages } from '@/utils/strings'
export default {
  name: 'TransportDetailsTooltip',
  data () {
    return {
      messages
    }
  },
  props: {
    transportDetails: {
      type: Array
    },
    target: {
      required: true,
      type: String
    },
    deliveryOrderDocNumber: {
      type: String
    }
  },
  computed: {
    showTooltip () {
      return this.transportDetails?.length
    }
  }
}
</script>

<style scoped>
.delivery-order-doc-number-style {
  list-style-type: none;
}
</style>
