<template>
  <b-tr
    class="order-row"
    @click="$emit('click', !item.showExpandRows)"
    :class="{ pointer: serviceOrder }"
    :data-stage="stage || 'StageMissing'"
    :data-purchase-order="purchaseOrder || '_na_'"
    :data-service-order="serviceOrder || '_na_'"
    :data-equipment-code="equipmentCode || '_na_'"
  >
    <b-th
      class="font-weight-normal"
      data-column-name="Delivery date"
      >
      <div class="d-flex align-items-center in-one-line">
        <span v-if="deliveryDateIsEta" data-variable="deliveryDateIsEta" :style="{ color: deliveryDateColor }">{{ eta | formatResponseDate }} {{ messages.getFieldEta() }}</span>
        <span v-else data-variable="deliveryDate" :style="{ color: deliveryDateColor }">{{ deliveryDate || eta | formatResponseDate }}</span>
        <i
          v-if="deliveryStatus === TO_BE_PICKED_UP"
          class="icon-follow text-warning icon-size ml-2"
        />
      </div>
    </b-th>
    <b-th
      class="font-weight-normal"
      data-column-name="Service order"
    >
      <div
        class="d-flex align-items-center"
      >
        <notification-icon
          :sms-notifications='smsNotifications'
          v-if="showNotificationIcons"
        />
        <i
          v-else
          class="icon-size icon-maintenance"
        />
        <div
          class="pl-3"
        >
          <div
            class="default-text-color"
          >

            <div v-if="showEquipmentInformation" data-context="Service Order and Equipment information">
              <div data-context="Equipment Description or Code" class="title">
                <!--
                  equipmentDescription is shown if it is available and if not then fallback option
                  is to show equipmentCode
                -->
                {{ equipmentInfo }}
              </div>
              <div data-context="Service Order" class="subtitle">
                {{ serviceOrder | formatMissing(messages.getLabelNoServiceOrder()) }}
              </div>
            </div>

            <div v-else data-context="Material info and other available information">
              <div
                data-variable="Material Code and Description"
                class="title"
              >
                <!-- Show link for specific customer. -->
                <b-link
                  v-if="materialCodeUrl"
                  :href="materialCodeUrl"
                  target="_blank"
                  data-context="Material Code"
                >
                  {{ materialCode | formatMissing(messages.getLabelNoMaterialCode()) }}
                </b-link>
                <span
                  v-else
                  data-context="Material Code"
                >
                  {{ materialCode | formatMissing(messages.getLabelNoMaterialCode()) }}
                </span>
                <!-- Material description is always shown -->
                <span data-context="Material Description">: {{ materialDescription | formatMissing(messages.getLabelNoMaterialDescription()) }}</span>
              </div>
              <span
                :data-variable="`serviceOrder.${serviceOrderFallback.name}`"
                data-context="Fallback value: Main Asset Number, Network, Cost Center or salesOrderDocNumberAtPO"
                class="subtitle"
                v-if="serviceOrderFallback.value"
              >
                {{ serviceOrderFallback.value }}
              </span>
            </div>
            <span v-if="trackingNumber" class="tracking-number">{{ trackingNumber }}</span>
          </div>
          <div
            v-if="!expandable"
          >
            {{ quantityForCurrentStage }}
          </div>
        </div>
      </div>
    </b-th><!-- End of Service Order column -->
    <b-th
      class="font-weight-normal"
      data-column-name="Purchase order"
    >
      <div
        v-if="!toBePurchased"
      >
        <div
          v-for="(line, key) in formattedPurchaseOrderRows"
          :key="key"
        >
          <span>
            {{ line | addComma(key, formattedPurchaseOrderRows) }}
          </span>
          <!-- orderDocNumberTooltipList returns number how many salesOrderDocNumbers are in related items.
                If there was one and row is not expandable then show badge GSS and if there is zero salesOrderDocNumbers and row is not
                expandable then show badge with text Local.
                If there are more than one salesOrderDocNumbers or row is expandable then expand row to see if it is local or GSS order.
          -->
          <span v-if="salesOrderDocFlow">
            <tag-badge :salesOrderDocFlow="salesOrderDocFlow" />
          </span>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-items-center"
      >
        <span>
          {{ actionMap.TO_BE_PURCHASED }}
        </span>
        <i
          class="icon-follow icon-size text-warning ml-2"
        />
      </div>
    </b-th>
    <b-th
      class="text-center"
      data-column-name="Confirmation"
    >
      <i
        v-if="confirmationIconColor"
        class="icon-assignment icon-size"
        :style="{ color: confirmationIconColor }"
        v-b-tooltip.hover
        :title="orderDocNumberTooltipList('salesOrderDocNumber')"
      />
    </b-th>
    <b-th
      class="text-center"
      data-column-name="Delivery"
    >
      <div
        v-if="deliveryIconColor"
      >
        <i
          class="icon-truck-1 icon-size"
          :style="{ color: deliveryIconColor }"
          v-b-tooltip.hover
          :title="orderDocNumberTooltipList('deliveryOrderDocNumber')"
          :id="componentId"
        />
        <transport-details-tooltip
          :delivery-order-doc-number="deliveryOrderDocNumber"
          :transport-details="transportDetails"
          :target="componentId"
        />
      </div>
    </b-th>
    <b-th
      class="d-flex font-weight-normal"
      data-column-name="Destination"
    >
      <div
        class="icon-container"
      >
        <i
          class="icon-size"
          :class="destinationIcon"
        />
      </div>
      <div
        class="pl-2 flex-column column-dark-text"
      >
        <span v-if="deliveryAddress" data-variable="deliveryAddress">{{ deliveryAddress }}</span>
        <span v-else-if="addressCode || storageLocation">
          <span v-if="addressCode" data-variable="addressCode">{{ addressCode }}</span>
          /
          <span v-if="storageLocation" data-variable="storageLocation">{{ storageLocation }}</span>
        </span>
      </div>
    </b-th>
    <b-th data-column-name="(Expand icon)">
      <i
        v-if="serviceOrder"
        :class="{ 'icon-chevron-down': !item.showExpandRows, 'icon-chevron-up': item.showExpandRows }"
      />
    </b-th>
  </b-tr>
</template>

<script>
import { TO_BE_PICKED_UP } from '@/constants/shared-constants'
import actionMap from '@/constants/action-map'
import { messages } from '@/utils/strings'
import {
  getOrderRowIconColor,
  getConfirmationColor,
  getDeliveryColor,
  getDestinationIcon,
  getDeliveryDateColor
} from '@/components/StatusMonitor/colors'
import { formatResponseDate, formatMissing } from '@/components/StatusMonitor/formatters'
import NotificationIcon from '@/components/StatusMonitor/Desktop/OrderTable/NotificationIcon'
import { showEquipmentInformation, getServiceOrderFallback } from '@/components/StatusMonitor/tracking-status'
import { quantityForStage } from '@/components/StatusMonitor/tracking-status-rows'
import TransportDetailsTooltip from '@/components/StatusMonitor/Desktop/OrderTable/TransportDetailsTooltip'
import TagBadge from '@/components/OrderDetails/TagBadge'
import { TAGS } from '@/constants/tags'

export default {
  name: 'OrderRow',
  components: { TransportDetailsTooltip, NotificationIcon, TagBadge },
  props: {
    item: { // TrackingStatusItem-like object.
      required: true,
      type: Object
    }
  },
  filters: {
    formatResponseDate,
    formatMissing,
    addComma (item, key, array) {
      const lastItem = !(array.length - 1 - key)
      if (!lastItem) {
        return item + ','
      }
      return item
    }
  },
  created () {
    this.GSS = TAGS.GSS
    this.LOCAL = TAGS.LOCAL
    this.TO_BE_PICKED_UP = TO_BE_PICKED_UP
    this.messages = messages
    this.actionMap = actionMap
  },
  methods: {
    logItem (event) {
      console.log({ item: this.item })
    },
    orderDocNumberTooltipList (propertyName) {
      if (!propertyName) {
        console.error('orderDocNumberTooltipList computed property requires propertyName')
      }
      const orderDocNumberList = this.item.trackingStatusItem?.items.map(
        item => item?.[propertyName]
      )
      // return list of unique OrderDocNumbers
      const tooltipList = [...new Set(orderDocNumberList)]
      // console.log(tooltipList)
      return tooltipList.sort()
    }
  },
  computed: {
    deliveryDateColor () {
      const orderRowSummary = {
        ...this.item.trackingStatusItem._orderRowSummary,
        _stage: this.item.trackingStatusItem._orderRowSummary?.stage
      }
      return getDeliveryDateColor(orderRowSummary)
    },
    trackingNumber () {
      return this.item.trackingStatusItem?._orderRowSummary?.trackingNumber
    },
    // Count all salesOrderDocNumbers and return result.
    salesOrderDocFlow () {
      const salesOrderDocNumberList = this.item.trackingStatusItem?.items.filter(
        item => {
          if (item.salesOrderDocNumber != null) {
            return item.salesOrderDocNumber
          }
        }
      )
      if (salesOrderDocNumberList?.length === 1 && !this.expandable) {
        return this.GSS
      } else if (salesOrderDocNumberList?.length === 0 && !this.expandable) {
        return this.LOCAL
      }
      return null
    },
    showEquipmentInformation: function () {
      return showEquipmentInformation({ equipmentCode: this.equipmentCode, serviceOrder: this.serviceOrder })
    },
    smsNotifications () {
      let itemList = []
      for (const item of this.item.trackingStatusItem?.items) {
        let itemDetails = []
        const smsTimestamps = item.smsTimestamps ?? []
        if (item.accessPointPhoneNumber && smsTimestamps.length > 0) {
          const accessPointPhoneNumberLine = { accessPhoneNumber: item.accessPointPhoneNumber }
          const smsDates = smsTimestamps.map(timestamp => ({ timestamp }))
          itemDetails = [accessPointPhoneNumberLine, ...smsDates]
          itemList = [...itemList, ...itemDetails]
        }
      }
      return itemList
    },
    transportDetails () {
      return this.item.trackingStatusItem._orderRowSummary?.transportDetails || []
    },
    componentId () {
      // unique vue component id
      return this._uid.toString()
    },
    stage: function () {
      return this.item.trackingStatusItem._orderRowSummary.stage
    },
    materialCodeUrl () {
      return this.$store.getters.createMaterialCodeUrl(this.materialCode)
    },
    arrMaterialDescription () {
      return this.item.trackingStatusItem._orderRowSummary.arrMaterialDescription
    },
    showNotificationIcons () {
      // Show SMS notification icons when there are SMS notifications
      return this.smsNotifications.length > 1 // > 1 means it has at least accessPointPhoneNumber and 1 timestamp
    },
    serviceOrderFallback () {
      return getServiceOrderFallback(this.item.trackingStatusItem._orderRowSummary)
    },
    materialCode () {
      return this.item?.trackingStatusItem?._orderRowSummary?.materialCode
    },
    materialDescription () {
      return this.item?.trackingStatusItem?._orderRowSummary?.materialDescription
    },
    toBePurchased () {
      const orderRowSummary = this.item.trackingStatusItem._orderRowSummary
      return !orderRowSummary.purchaseOrderDocNumber && !orderRowSummary.salesOrderDocNumber &&
        !orderRowSummary.deliveryOrderDocNumber && orderRowSummary.reservationDocNumber
    },
    expandable: function () {
      return !!this.serviceOrder
    },
    deliveryDate: function () {
      // console.log({ _: 'deliveryDate', this_: this, item: this.item })
      return this.item.trackingStatusItem._orderRowSummary?.deliveryDate
    },
    eta: function () {
      return this.item.trackingStatusItem._orderRowSummary?.eta
    },
    deliveryDateIsEta: function () {
      return this.item.trackingStatusItem._orderRowSummary?.deliveryDateIsEta
    },
    deliveryType: function () {
      return this.item.trackingStatusItem._orderRowSummary?.deliveryType
    },
    deliveryStatus: function () {
      return this.item.trackingStatusItem._orderRowSummary?.deliveryStatus
    },
    deliveryAddress: function () {
      return this.item.trackingStatusItem._orderRowSummary?.deliveryAddress
    },
    addressCode: function () {
      return this.item.trackingStatusItem._orderRowSummary?.addressCode
    },
    storageLocation: function () {
      return this.item.trackingStatusItem._orderRowSummary?.storageLocation
    },
    purchaseOrder: function () {
      return this.item.trackingStatusItem._orderRowSummary?.purchaseOrder
    },
    deliveryOrderDocNumber: function () {
      return this.item.trackingStatusItem._orderRowSummary?.deliveryOrderDocNumber
    },
    serviceOrder: function () {
      return this.item.trackingStatusItem.serviceOrder
    },
    equipmentCode: function () {
      return this.item.trackingStatusItem.equipmentCode
    },
    equipmentInfo: function () {
      return this.item.trackingStatusItem.equipmentDescription ?? this.item.trackingStatusItem.equipmentCode
    },
    formattedPurchaseOrderRows: function () {
      return this.item.trackingStatusItem._formattedPurchaseOrderRows
    },
    /**
     * Filter out other than material code rows.
     * @returns - All material code item rows.
     */
    materialCodeItems: function () {
      const itemRows = this.item.expandRows?.filter(row => row.rowType === 'materialCodeItem')
      return itemRows ?? []
    },
    confirmationIconColor: function () {
      if (this.expandable) {
        // Look for material code item rows and get the icon color to show in the order row.
        const colors = this.materialCodeItems.map(getConfirmationColor)
        return getOrderRowIconColor(colors)
      }
      // Not expandable row, so let's take the first response item from the list. There should be exactly one?
      if (this.item.trackingStatusItem?.items?.length !== 1) {
        console.warn({ _: 'Expected TrackingStatus items length to be 1', orderRowItem: this.item })
      }
      const firstItem = this.item.trackingStatusItem?.items?.[0]
      const deliveryInfo = {
        stage: firstItem?._stage,
        deliveryStatus: firstItem?.deliveryStatus,
        deliveryType: firstItem?.deliveryType,
        salesOrderDocNumber: firstItem?.salesOrderDocNumber,
        purchaseOrderConfirmationCategory: firstItem?.purchaseOrderConfirmationCategory
      }
      if (!deliveryInfo.stage) {
        // This is for debugging. Stage should at least be `UnknownStage`.
        console.warn({ _: 'Stage missing when getting confirmationIconColor for orderRow', orderRowItem: this.item })
      }
      return getConfirmationColor(deliveryInfo)
    },
    deliveryIconColor: function () {
      if (!this.expandable) {
        // Row is not expandable so get the color code for it.
        return getDeliveryColor(this.item.trackingStatusItem._orderRowSummary)
      }
      // Look for material code item rows and get the icon color to show in the order row.
      const colors = this.materialCodeItems.map(getDeliveryColor)
      return getOrderRowIconColor(colors)
    },
    destinationIcon: function () {
      return getDestinationIcon(this.item.trackingStatusItem._orderRowSummary)
    },
    quantityForCurrentStage: function () {
      return quantityForStage(this.stage, this.item.trackingStatusItem)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/custom.scss';
$icon-font-size: 26px;
// FIXME: Refactor duplicate classes to as non-scoped.
// FIXME: Use more readable color variables like "text", instead of "gray-600".
.icon-size {
  font-size: $icon-font-size;
}
.icon-maintenance {
  color: $gray-400;
}
.icon-follow {
  color: $warning;
  padding-left: 2px;
}
.default-text-color {
  color: initial;
}
.pointer {
  cursor: pointer;
}
.icon-container {
  min-width: $icon-font-size;
}
.title {
  color: $text-primary;
}
.subtitle {
  color: $primary;
}
.green {
  color: $green;
}
.gray {
  color: $gray-400;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.column-dark-text {
  color: $gray-900;
}
a {
  text-decoration: underline;
}
.in-one-line {
  white-space: nowrap;
}
.tracking-number {
  color: $gray-900;
}
// If serviceOrderFallback.value (and the span containing it) is present then this selector
// will add margin between the span and span with class .tracking-number
span + .tracking-number {
  margin-left: 20px;
}
</style>
