<template>
  <div
    class="pt-3"
  >
    <span>
      {{ messages.getPageStatusFilter() }}:
    </span>
    <div
      class="d-flex align-items-center pt-2"
    >
      <pressure-button-selection
        type="desktop"
        v-model="status"
        :options="BUTTONS"
        class="w-100"
      />
      <div
        class="pl-4"
        :class="{ 'not-visible': !showMyOrders }"
      >
        <b-form-checkbox
          :checked="myOrders"
          v-model="myOrders"
          @change="searchOptionChanged('myOrders', $event)"
          size="lg"
          class="no-wrap clickable"
        >
          {{ messages.getPageStatusMyOrders() }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import { STATUS_ALL, STATUS_ARRIVED, STATUS_INCOMING } from '@/constants/shared-constants'
import PressureButtonSelection from '@/components/FilterPanel/PressureButtonSelection'

const BUTTONS = [
  { label: messages.getPageStatusTabAll(), value: STATUS_ALL },
  { label: messages.getPageStatusTabArrived(), value: STATUS_ARRIVED },
  { label: messages.getPageStatusTabIncoming(), value: STATUS_INCOMING }
]
export default {
  name: 'SearchOptions',
  components: { PressureButtonSelection },
  data () {
    return {
      statusSnapshot: null,
      messages
    }
  },
  computed: {
    showMyOrders () {
      return this.$store.getters.useMyOrders
    },
    status: {
      get () {
        return this.$store.getters['statusMonitor/userSearchOptions'].status
      },
      set (status) {
        this.searchOptionChanged('status', status)
        this.$store.commit('statusMonitor/setSearchOptionStatus', status)
      }
    },
    myOrders: {
      get () {
        return this.$store.getters['statusMonitor/userSearchOptions'].myOrders
      },
      set (myOrders) {
        this.$store.commit('statusMonitor/setSearchOptionMyOrders', myOrders)
      }
    }
  },
  methods: {
    /**
     * Triggered when Status option is changed or My Orders is changed.
     *
     * @param {any} value - Value from the input element event.
     * @param {String} source - Which element was changed.
     */
    searchOptionChanged (source, value) {
      // console.log({ _: 'searchOptionChanged', source, value })
      //
      // In practise the source and value can be ignored. This is just to inform
      // the FilterPanel that we need to refrest the Tracking Status list (by
      // making a new search).
      this.$nextTick().then(() => {
        // Because the Vuex store updates for the next tick, it safer to
        // emit the change in the next tick. And by safer I mean required.
        this.$emit('change', { source, value })
      })
    }
  },
  created () {
    this.BUTTONS = BUTTONS
  }
}
</script>

<style scoped lang="scss">
.no-wrap {
  white-space: nowrap;
}

.not-visible {
  visibility: hidden;
}

/* Show pointer cursor for checkbox */
.clickable {
  cursor: pointer;
}
.clickable ::v-deep {
  label {
    cursor: pointer;
  }
  input {
    cursor: pointer;
  }
}
</style>
