<template>
  <div>
    <top-panel-button
      @click="openMenu"
      aria-controls="status-filter-menu-dialog"
      aria-haspopup="dialog"
    >
      {{ dropdownStatusMenuTitle }}
      <i
        class="icon-size pl-2"
        :class="{ 'icon-chevron-up': showMenu, 'icon-chevron-down': !showMenu }"
      ></i>
    </top-panel-button>
    <bottom-dialog
      v-model="showMenu"
      id="status-filter-menu-dialog"
    >
      <pressure-button-selection
        type="mobile"
        v-model="status"
        :options="BUTTONS"
        class="w-100"
      />
      <bottom-dialog-divider />
      <pressure-button
        type="mobile"
        :pressed="myOrders"
        class="w-100"
        @click="toggleMyOrders"
      >
        {{ messages.getActionOnlyShowMyOrders() }}
      </pressure-button>
    </bottom-dialog>
  </div>
</template>

<script>
/**
 * Mobile view only filter menu, where user can select all, arrived, incoming next and "only my orders".
 */
import { messages } from '@/utils/strings'
import { STATUS_ALL, STATUS_ARRIVED, STATUS_INCOMING, MOBILE_MAX_RESULTS } from '@/constants/shared-constants'
import BottomDialog from '@/components/TopPanel/BottomDialog'
import BottomDialogDivider from '@/components/TopPanel/BottomDialogDivider'
import TopPanelButton from '@/components/TopPanel/TopPanelButton'
import PressureButton from '@/components/FilterPanel/PressureButton'
import PressureButtonSelection from '@/components/FilterPanel/PressureButtonSelection'

const BUTTONS = [
  { label: messages.getPageStatusTabAll(), value: STATUS_ALL },
  { label: messages.getPageStatusTabArrived(), value: STATUS_ARRIVED },
  { label: messages.getPageStatusTabIncoming(), value: STATUS_INCOMING }
]

export default {
  name: 'StatusFilterMenu', // FIXME: Rename this so that it is not confused with FilterPanel.
  components: {
    BottomDialog,
    BottomDialogDivider,
    PressureButton,
    PressureButtonSelection,
    TopPanelButton
  },
  data () {
    return {
      // Updated when the filter menu is popped up and used to check
      // if user made any changes so that a new search can be issued.
      searchOptionsSnapshot: {},
      showMenu: false,
      messages
    }
  },
  computed: {
    dropdownStatusMenuTitle () {
      return BUTTONS.find(button => button.value === this.status)?.label
    },
    showMyOrders () {
      return this.$store.getters.useMyOrders
    },
    status: {
      get () {
        return this.$store.getters['statusMonitor/userSearchOptions'].status
      },
      set (status) {
        this.showMenu = false
        this.$store.commit('statusMonitor/setSearchOptionStatus', status)
      }
    },
    myOrders: {
      get () {
        return (
          this.$store.getters['statusMonitor/userSearchOptions'].myOrders
        )
      },
      set (myOrders) {
        this.$store.commit('statusMonitor/setSearchOptionMyOrders', myOrders)
      }
    }
  },
  methods: {
    toggleMyOrders () {
      this.myOrders = !this.myOrders
    },
    openMenu () {
      this.showMenu = true
    },
    closeMenu () {
      this.showMenu = false
    },
    searchIfFiltersChanged () {
      if (
        this.searchOptionsSnapshot?.status !== this.status ||
        this.searchOptionsSnapshot?.myOrders !== this.myOrders
      ) {
        // We could emit an event here...
        // this.$emit('filters-changed')
        const query = this.$store.getters['statusMonitor/searchText']
        this.$store.dispatch('statusMonitor/search', { query, maxResults: MOBILE_MAX_RESULTS })
      }
    }
  },
  watch: {
    showMenu (isOpenNow) {
      if (isOpenNow) {
        // Store the state of filters when the menu is opened
        this.searchOptionsSnapshot = {
          status: this.status,
          myOrders: this.myOrders
        }
      } else {
        // TODO: Add smoother transition _TO CSS_ so that the hiding is not so sudden.
        // Make a new search if user changed something so that stale
        // data is not shown. This has to happen on next tick as the Vuex
        // getters have to be updated before the comparison to the snapshot.
        this.$nextTick().then(this.searchIfFiltersChanged)
      }
    }
  },
  created () {
    this.BUTTONS = BUTTONS
  }
}
</script>

<style scoped lang="scss">
.icon-size {
  font-size: 14px;
}
</style>
