<template>
  <b-col
    class="text-white h-50 text-center"
  >
    <div
      class="top-text"
    >{{ topText | formatMissing(messages.getLabelNa()) | formatArray }}</div>
    <div>{{ bottomText }}</div>
  </b-col>
</template>

<script>
import { messages } from '@/utils/strings'
import { formatMissing } from '@/components/StatusMonitor/formatters'

export default {
  name: 'DashboardCell',
  data () {
    return {
      messages
    }
  },
  filters: {
    formatMissing,
    formatArray: function (value) {
      if (Array.isArray(value)) {
        return `${value[0]}/${value[1]}`
      } else {
        return value
      }
    }
  },
  props: {
    topText: {
      required: false,
      type: [String, Array]
    },
    bottomText: {
      required: false,
      type: String
    }
  }
}
</script>

<style scoped>
.top-text {
  font-size: 32px;
  height: 67%;
}

</style>
