<template>
  <b-button
    v-if="type === 'desktop'"
    :pressed="pressed"
    squared
    size="lg"
    class="pressure-button-desktop text-uppercase"
    @click="onClick"
  >
    <slot />
  </b-button>
  <b-button
    v-else-if="type === 'mobile'"
    :pressed="pressed"
    variant="outline"
    squared
    size="lg"
    class="pressure-button-mobile"
    @click="onClick"
  >
    <i
      class="icon-check text-success"
      :class="{ 'hide-icon': !pressed }"
    />
    <slot />
  </b-button>
</template>

<script>
export const TYPES = ['desktop', 'mobile']

/**
 * A button that can be pressed down to indicate selection.
 *
 * Is mostly interchangeable with a checkbox.
 *
 * The main difference is that pressure buttons generally indicate that an
 * event of some kind happens when they are pressed, whereas checkboxes are
 * more inert.
 *
 * Just like `PressureButtonSelection`, has 2 types: 'desktop' and 'mobile'.
 * Rename them or add new ones whenever necessary.
 */
export default {
  name: 'PressureButton',
  props: {
    type: {
      type: String,
      required: true,
      validator: value => TYPES.includes(value)
    },
    pressed: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.pressure-button-desktop {
  background-color: $light;
  padding-top: 1px !important;
  padding-bottom: 1px !important;

  &.active {
    background-color: $blue-400 !important;
    color: $light !important;
  }
}

.icon-size {
  font-size: 14px;
}

.icon-check {
  font-size: 13px;
}

.pressure-button-mobile {
  text-align: left !important;
  padding-left: 1.875rem !important;
  margin: 3px 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.hide-icon {
  /* hide the "checked" icon, but preserve the spacing. */
  visibility: hidden;
}
</style>
