<template>
  <div>
    <b-list-group
      flush
      class="w-100"
    >
      <b-list-group-item
        class="text-center"
        v-show="!trackingStatusItems.length && !ongoingQuery"
      >
        {{ messages.getLabelNoItemsFound() }}
      </b-list-group-item>
      <order-list-item
        v-for="(item, key) in trackingStatusItems"
        class="order-list-group"
        :key="key"
        :to="to(item)"
        :order-list-item="item"
      />
    </b-list-group>
    <div id="infinite-scroll-sentinel" data-context="Infinite scroller sentinel">&nbsp;</div>
    <div v-show="!hasNextPage && trackingStatusItems.length">
      <!-- "No items found" is shown instead of "All items shown" -->
      <div class="all-items-shown">{{ messages.getLabelAllItemsShown() }}</div>
    </div>

  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import OrderListItem from './OrderListItem'
import { MOBILE_MAX_RESULTS } from '@/constants/shared-constants'
let SCROLL_OBSERVER = null

export default {
  // FIXME: This is called OrderListMobile, but the component doing the exactly same thing is called OrderTable. Not
  // FIXME: to be confused with OrderList, which is *not* the desktop equivalient.
  // FIXME: Rethink the naming of OrderList (wrapper), OrderListMobile and OrderTable (desktop view).
  name: 'OrderListMobile',
  components: { OrderListItem },
  props: {
  },
  data () {
    return {
      items: [],
      messages
    }
  },
  computed: {
    trackingStatusItems () {
      return this.$store.getters['statusMonitor/materialGroupTrackingStatuses']
    },
    hasNextPage () {
      return this.$store.getters['statusMonitor/hasNextPage']
    },
    ongoingQuery () {
      return this.$store.getters['statusMonitor/ongoingQuery']
    },
    scroll () {
      return document.scrollingElement.scrollTop
    }
  },
  methods: {
    to (item) {
      if (item.serviceOrder) {
        return {
          name: 'OrderDetails',
          params: { orderId: item.serviceOrder, orderDetails: item }
        }
      }
      return {}
    },
    /**
     * Fetch the next page for current search
     *
     * This is used by `triggerInfiniteScroll` event, but can be used directly if needed (eg. "load more button").
     *
     * Check that `hasNextPage` is true and that there is no `ongoingQuery`.
     */
    fetchNextPage () {
      const responsePromise = this.$store.dispatch('statusMonitor/fetchNextPage', { maxResults: MOBILE_MAX_RESULTS })
      const viewWrapper = document.scrollingElement
      // Store the position where the user has scrolled the list
      const scrollPosition = viewWrapper.scrollTop
      responsePromise.then((response) => {
        // Restore the postion to where it was before more items were added to the list.
        // This is a quick hack to prevent the view from scrolling to the bottom of
        // the list after new items have been fetched. Not preserving the position
        // would be very confusing for the user.
        // FIXME: @see MobileLoadingSpinner.vue:ongoingQuery. Using overflow-hidden
        // causes the view in the background jump during the request.
        viewWrapper.scrollTop = scrollPosition
      })
    },
    triggerInfiniteScroll (entries, observer) {
      // Oberver is triggered
      entries.forEach((entry) => {
        // There should only be one entry, the #infinite-scroll-sentinel element
        if (!entry.isIntersecting) {
          // User "scrolled up" ie. the "negative" trigger
          return
        }
        if (!this.hasNextPage) {
          // The search has been exhausted and there is no pages to fetch. Infinite scroll stops here, but
          // the observer should be left active because it is initialised only when this element is mounted.
          return
        }
        if (this.ongoingQuery) {
          // There already is a request in process. This is probably the request from initial page load.
          // We don't want to create a race condition here and the user can always just "scroll up" and
          // "scroll down again" to retry.
          return
        }
        // Everything seems right and we want to load the next page!
        this.fetchNextPage()
      })
    },
    initialiseInfiniteScroll () {
      const options = {
        root: null, // Is there a better root value?
        rootMargin: '0px',
        threshold: 1.0
      }
      SCROLL_OBSERVER = new IntersectionObserver(this.triggerInfiniteScroll, options)
      const element = document.getElementById('infinite-scroll-sentinel')
      SCROLL_OBSERVER.observe(element)
    }
  },
  watch: {
    scroll (newValue) {
      console.log(`scroll value ${newValue}`)
    }
  },
  mounted () {
    if (SCROLL_OBSERVER === null) {
    // We must register the observer every time the element is created.
      this.initialiseInfiniteScroll()
    }
  },
  beforeDestroy () {
    if (SCROLL_OBSERVER) {
      // Clean up the observer. If this element is destroyed, the observer does not
      // function any more (when this element is created again), so we should
      // disconnect it just in case it is still kept in memory or is observing
      // something unuseful.
      SCROLL_OBSERVER.disconnect()
      SCROLL_OBSERVER = null
    }
  }
}
</script>

<style scoped lang="scss">
#infinite-scroll-sentinel {
  height: 1px;
  width: 1px;
  visibility: hidden;
}
.all-items-shown {
  text-align: center;
  color: $subtitle;
}
</style>
